<template lang="html">
  <section class="month">
    <v-overlay data-cy="loading-overlay" :value="this.showMonthLoadingOverlay" absolute />

    <Flicker :loading="this.loading"
      :bottomText="monthData && monthData.user ? `Monatsabschluss: ${monthData.user.firstname} ${monthData.user.lastname}` : 'Monatsabschluss:'"
      @changeRoute="this.changeRoute">
    </Flicker>
    
    <section v-if="this.showMonth" id="showMonthData">

      <MonthTable :monthData="this.monthData" :entryUpdateEnabled="false" :attachments="this.attachments" 
        v-if="this.monthData.weeks"></MonthTable>

      <!-- Monatsabschluss -->
      <v-row class="pl-12 pb-6 pt-6 pr-12 d-flex" align="center" justify="end">
        <!-- TODO: remove && this.monthData.closing.timestamp check later when we can be sure that all users use this version -->
        <v-col cols="auto">
          <v-card v-if="this.monthData.closing" class="d-flex flex-column text-center" flat color='transparent'>
            <label class="grey--text overline">Abgeschlossen am</label>
            <label id="timestamp">{{ formatTimestamp(monthData.closing.timestamp) }}</label>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card v-if="this.monthData.closing" class="d-flex flex-column text-center" flat color='transparent'>
            <label class="grey--text overline">
              Status
              <v-tooltip v-if="this.monthData.closing.latestComment" color="grey darken-4" right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="grey lighten-1">mdi-message</v-icon>
                </template>
                <div id="commentbody" style="white-space: pre-line;">{{ this.monthData.closing.latestComment.body }}</div>
              </v-tooltip>
            </label>
            <label id="status">{{ monthData.closing.status }}</label>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-btn-toggle v-if="this.monthApproveableTL" dense class="ml-4">
            <v-btn id="approveMonthTL" color="#c00000" class="white--text" @click="approveMonthTL()">
              Bestätigen
            </v-btn>
            <v-btn id="approveMonthTLWithComment" color="#c00000" class="white--text"
              @click="approveMonthTLWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.monthApproveableHR" dense class="ml-4">
            <v-btn id="approveMonthHR" color="#c00000" class="white--text" @click="approveMonthHR()">
              Genehmigen
            </v-btn>
            <v-btn id="approveMonthHRWithComment" color="#c00000" class="white--text"
              @click="approveMonthHRWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.monthRejectable" dense class="ml-4">
            <v-btn id="rejectMonth" color="#c00000" class="white--text" @click="rejectMonth()">
              Zurückweisen
            </v-btn>
            <v-btn id="rejectMonthWithComment" color="#c00000" class="white--text" @click="rejectMonthWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.backToTLable" dense class="ml-4">
            <v-btn id="rejectMonthToTL" color="#c00000" class="white--text" @click="backToTL()">
              Rückfrage TL
            </v-btn>
            <v-btn id="rejectMonthToTLWithComment" color="#c00000" class="white--text" @click="backToTLWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.monthFixable" dense class="ml-4">
            <v-btn id="gotoMonatsansicht" color="#c00000" class="white--text" @click="gotoMonatsansicht()">
              Monatsansicht
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.showGotoTicket" dense class="ml-4">
            <v-btn id="gotoJiraTicket" color="#c00000" class="white--text"
              :href="global.JIRA_SERVER_URL + '/browse/' + monthData.closing.jiraKey" target="_blank">
              Jira Ticket
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!-- /Monatsabschluss -->

    </section>
    <v-row v-else-if="!this.loading" no-gutters class="d-flex justify-center">
      <br><br>
      Keine Daten vorhanden
    </v-row>

    <ConfirmExtended ref="confTransition"></ConfirmExtended>
    <Confirm ref="confNotFound" confirmButtonText="Weiter" :cancelButtonText="null"></Confirm>
  </section>
</template>

<script lang="js">
import dayjs from '@util/dayjs';

import Flicker from '@components/Flicker.vue';
import MonthTable from '@components/user/MonthTable.vue';
import Confirm from '@components/Confirm.vue';
import ConfirmExtended from '@components/ConfirmExtended.vue';

import * as monatsabschlussClient from '@client/client.monatsabschluss';

import JiraMonatsabschluss from '@/mixins/JiraMonatsabschluss.vue';

export default {
  name: 'Monatsabschluss',
  components: {
    MonthTable,
    Confirm,
    ConfirmExtended,
    Flicker
  },
  mixins: [JiraMonatsabschluss],

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadMonth);
  },

  mounted() {
    this.initialLoadMonth();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadMonth);
  },
  
  data() {
    return {
      loading: false,
      initialMonthLoad: true,
      isFromJiraKey: true,
      monthData: {},
      attachments: []
    }
  },

  computed: {
    showMonth: function () {
      return (this.showMonthLoadingOverlay || (this.loading == false)) && this.monthData.user;
    },

    showMonthLoadingOverlay: function () {
      return this.loading && this.initialMonthLoad == false;
    },

    displayedDate: function () {
      return this.$store.state.displayedMonth;
    }
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    async initialLoadMonth() {
      this.initialMonthLoad = true;
      await this.loadMonth();
    },

    async reloadMonth() {
      await this.loadMonth();
    },

    async loadMonth() {
      this.loading = true;
      const res = await this.$draf(async () => {
        const staticUserData = this.$store.getters.staticUserData;
        if (staticUserData == undefined || this.$store.getters.userAuthenticationData.mocoApiKey == undefined) {
          return;
        }

        const loadedJiraKey = this.$route?.query?.jiraTicket;
        const urlParams = this.$route.params;
        let jiraTicket;
        if (loadedJiraKey) {
          jiraTicket = await monatsabschlussClient.getMonatsabschluss(loadedJiraKey);
          if (jiraTicket?.month == undefined) {
            await this.openConfirmation(`Das aufgerufene Jira-Ticket (${loadedJiraKey}) existiert nicht. Sie werden nun zum aktuellen Monat weitergeleitet.`);
            return () => { this.$router.push({ path: global.ROUTES.aktuellermonat.path }); };
          }
          const date = dayjs(jiraTicket.month.startOfMonth);
          const username = jiraTicket.reporter.emailAddress.split('@')[0];
          return () => { this.$router.push({ name: global.ROUTES.monatsabschluss.name, params: { username: username, year: date.year(), month: date.isoMonth() } }) };
        } else {
          const email = `${urlParams.username}@seqis.com`;

          jiraTicket = await monatsabschlussClient.getMonatsabschluss(undefined, email, this.displayedDate.isoMonth(), this.displayedDate.year());
          if (jiraTicket?.month == undefined) {
            await this.openConfirmation(`Für den angegebenen User, Jahr und Monat existiert kein Monatsabschluss. `);
            return () => { this.$router.push(this.$store.state.prevRoute)}
          }
          this.isFromJiraKey = false;
        }

        this.loadMonthFromJiraTicket(jiraTicket);
      });

      this.initialMonthLoad = false;
      this.loading = false;
      if (typeof (res) == 'function') {
        res();
      }
    },

    async openConfirmation(message) {
      this.loading = false;
      await this.$refs.confNotFound.open('Monatsabschluss wurde nicht geladen', message);
    },

    changeRoute(displayedDate) {
      this.$router.push({
        name: global.ROUTES.monatsabschluss.name,
        params: {
          username: this.$route.params.username,
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        }
      });
    },

    gotoMonatsansicht() {
      this.$router.push({
        name: global.ROUTES.monatsansicht.name,
        params: {
          username: this.$route.params.username,
          year: this.$store.state.displayedMonth.year(),
          month: this.$store.state.displayedMonth.isoMonth()
        }
      });
    }
  },
  watch: {
    displayedDate: function () {
      this.initialLoadMonth();
    }
  },
}
</script>
<style type="scss">
#commentbody {
  white-space: pre-line;
}

.v-btn-toggle {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.v-btn-toggle>.v-btn {
  opacity: 1 !important;
}
</style>