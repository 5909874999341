<template lang="html">
  <section class="month">
    <v-overlay data-cy="loading-overlay" :value="this.showMonthLoadingOverlay" absolute />

    <Flicker :bottomText="this.isMonthFromMonatsabschluss ? '(Monatsabschluss)' : ''" :loading="this.loading"
      @changeRoute="this.changeRoute">
    </Flicker>

    <section id="showMonthData" v-show="this.showMonth">

      <MonthTable :monthData="this.monthData" :entryUpdateEnabled="isMonthFromMonatsabschluss == false && global.impersonationID == undefined" :attachments="this.attachments" 
        v-if="this.monthData.weeks"></MonthTable>

      <!-- Monatsabschluss -->
      <v-row class="pl-12 pb-6 pt-6 pr-12 d-flex" align="center" justify="end">
        <!-- TODO: remove && this.monthData.closing.timestamp check later when we can be sure that all users use this version -->
        <v-col cols="auto">
          <v-card v-if="this.isMonthFromMonatsabschluss && this.monthData.closing && this.monthData.closing.timestamp"
            class="d-flex flex-column text-center" flat color='transparent'>
            <label class="grey--text overline">Abgeschlossen am</label>
            <label id="timestamp">{{ formatTimestamp(this.monthData.closing.timestamp) }}</label>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-card v-if="this.monthData.closing" class="d-flex flex-column text-center" flat color='transparent'>
            <label class="grey--text overline">
              Status
              <v-tooltip v-if="this.monthData.closing.latestComment" color="grey darken-4" right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="grey lighten-1">mdi-message</v-icon>
                </template>
                <div id="commentbody" style="white-space: pre-line;">{{ this.monthData.closing.latestComment.body }}</div>
              </v-tooltip>
            </label>
            <label id="status">{{ monthData.closing.status }}</label>
          </v-card>
        </v-col>
        <v-col cols="auto">
          <v-btn-toggle v-if="this.monthSubmitable" dense class="ml-4">
            <v-btn id="submitMonth" color="#c00000" class="white--text" @click="submitMonth()">
              Monat abschließen
            </v-btn>
            <v-btn id="submitMonthWithComment" color="#c00000" class="white--text" @click="submitMonthWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.monthFixable" dense class="ml-4">
            <v-btn id="fixMonth" color="#c00000" class="white--text" @click="fixMonth()">
              Erneut abschließen
            </v-btn>
            <v-btn id="fixMonthWithComment" color="#c00000" class="white--text" @click="fixMonthWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.monthRejectable" dense class="ml-4">
            <v-btn id="rejectMonth" color="#c00000" class="white--text" @click="rejectMonth()">
              Zurückziehen
            </v-btn>
            <v-btn id="rejectMonthWithComment" color="#c00000" class="white--text" @click="rejectMonthWithComment()">
              <v-icon color="white">mdi-message</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle v-if="this.showGotoTicket" dense class="ml-4">
            <v-btn id="gotoJiraTicket" color="#c00000" class="white--text"
              :href="global.JIRA_SERVER_URL + '/browse/' + monthData.closing.jiraKey" target="_blank">
              Jira Ticket
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!-- /Monatsabschluss -->

    </section>

    <ConfirmExtended ref="confTransition"></ConfirmExtended>
    <ConfirmExtended ref="confMonatsabschluss"></ConfirmExtended>
    <Confirm ref="confJump" confirmButtonText="Ja" cancelButtonText="Nein"></Confirm>
    <Confirm ref="confNotFound" confirmButtonText="Weiter" :cancelButtonText="null"></Confirm>
  </section>
</template>

<script lang="js">
import dayjs from '@util/dayjs';

import Flicker from '@components/Flicker.vue';
import MonthTable from '@components/user/MonthTable.vue'
import Confirm from '@components/Confirm.vue';
import ConfirmExtended from '@components/ConfirmExtended.vue';
import JiraMonatsabschluss from '@/mixins/JiraMonatsabschluss.vue'

import * as sqtClient from '@client/client.sqt';
import * as monatsabschlussClient from '@client/client.monatsabschluss';
import * as mocoClient from '@client/client.moco';

export default {
  name: 'Monatsansicht',
  components: {
    Confirm,
    ConfirmExtended,
    MonthTable,
    Flicker
  },

  mixins: [JiraMonatsabschluss],

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadMonth);
  },

  mounted() {
    this.initialLoadMonth();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadMonth);
  },

  data() {
    return {
      loading: false,
      initialMonthLoad: true,
      isMonthFromMonatsabschluss: false,
      monthData: {
        /*closing: {
          status: undefined, // wg. reactiviy
          jiraKey: undefined // wg. reactiviy
        },
        hours: {}, // wg. sicherem Zugriff auf monthData.hours.X
        holidays: {}, // wg. sicherem Zugriff auf monthData.holidays.X*/
      },
      attachments: []
    }
  },

  computed: {
    showMonth: function () {
      return this.showMonthLoadingOverlay || (this.loading == false);
    },

    showMonthLoadingOverlay: function () {
      return this.loading && this.initialMonthLoad == false;
    },

    monatsabschlussStillOpenConfirmed: function () {
      return this.$store.state.confirmations.MonatsabschlussStillOpen == 'confirmed';
    },

    displayedDate: function () {
      return this.$store.state.displayedMonth;
    }
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    async initialLoadMonth() {
      this.initialMonthLoad = true;
      this.isMonthFromMonatsabschluss = false;

      await this.loadMonth();
    },

    loadTodaysMonth() {
      this.changeRoute(dayjs().date(1));
    },

    async reloadMonth() {
      this.$root.$emit('activityTreeReloadNeeded');
      await this.loadMonth();
    },

    async loadMonth() {
      this.loading = true;
      const res = await this.$draf(async () => {
        const staticUserData = this.$store.getters.staticUserData;
        if (staticUserData == undefined || this.$store.getters.userAuthenticationData.mocoApiKey == undefined) {
          return;
        }

        const jiraTicket = await monatsabschlussClient.getMonatsabschluss(undefined, staticUserData.user.email, this.displayedDate.isoMonth(), this.displayedDate.year());
        this.attachments = jiraTicket?.attachments;
        if (jiraTicket && jiraTicket.status != global.monthClosing.status.open) { 
            if (!jiraTicket.month) {
              await this.openConfirmation(this.$refs.confNotFound, 'Monatsdaten nicht gefunden',
                `Die Monatsdaten konnten nicht aus dem Jira-Ticket (${jiraTicket.jiraKey}) geladen werden. Sie werden nun zum aktuellen Monat weitergeleitet.`);
              return () => { this.changeRoute(dayjs().date(1)) };
            }

            if (jiraTicket.status === global.monthClosing.status.rejected) {
              // Wenn "Rejected" muss das Monat aus den realen Moco Daten geladen werden, damit man die Änderungen im SEQITRacker sieht und erneut abschließen kann
              await this.loadMonthFromMoco();
              this.monthData.closing.latestComment = jiraTicket.latestComment;
            } else {
              this.isMonthFromMonatsabschluss = true;
              this.loadMonthFromJiraTicket(jiraTicket);
            }
        } else {
          await this.loadMonthFromMoco();

          if (this.displayedDate.isBefore(dayjs().subtract(1, 'month'), 'month')) {
            await this.openConfirmation(this.$refs.confNotFound, 'Monatsdaten nicht gefunden',
              'Für diesen Monat wurde kein Monatsabschluss gefunden, obwohl er schon mehrere Monate zurück liegt. Die Daten, die jetzt angezeigt werden kommen direkt von Moco. Falls der Monat eigentlich schon abgeschlossen ist warte kurz und lad die Seite dann neu. :)');
          }
        }

        const prevDate = dayjs().subtract(1, 'months');
        const prevMonthJiraTicket = await monatsabschlussClient.getMonatsabschluss(undefined, staticUserData.user.email, prevDate.isoMonth(), prevDate.year()); // TODO ohne monthData laden

        if (this.monatsabschlussStillOpenConfirmed == false && (prevMonthJiraTicket == undefined || prevMonthJiraTicket == '')) { //this.monthData.closing.status == global.monthClosing.status.prevMonthOpen
          // previous month was not submitted
          if (this.displayedDate.isSame(prevDate, 'month') == false) {
            this.$store.dispatch('setConfirmation', { confirmation: 'MonatsabschlussStillOpen', status: 'confirmed' });
            if (await this.openConfirmation(this.$refs.confJump, 'Zu offenem Monat springen?',
              'Der Abschluss eines vergangenen Monats ist noch nicht erfolgt. Wollen Sie zu diesem wechseln?')) {
              return () => { this.changeRoute(prevDate) };
            }
          }
        }
      });

      this.initialMonthLoad = false;
      this.loading = false;
      if (typeof (res) == 'function') {
        res();
      }
    },

    async loadMonthFromMoco() {
      this.isMonthFromMonatsabschluss = false;

      const staticUserData = this.$store.getters.staticUserData;
      const startOfMonth = this.displayedDate.startOf('month').startOf('day');
      const endOfMonth = this.displayedDate.endOf('month').endOf('day');

      const activitiesPromise = mocoClient.getActivities(startOfMonth, endOfMonth);
      const presencesPromise = mocoClient.getPresences(startOfMonth, endOfMonth);

      const [activities, presences] = await Promise.all([activitiesPromise, presencesPromise]);

      this.monthData = await sqtClient.getMonatsdaten(
        this.displayedDate.isoMonth(), this.displayedDate.year(),
        activities, presences,
        staticUserData.userEmployments, staticUserData.userHolidays, staticUserData.schedules,
        this.$store.getters.enabledRules
      );
    },

    async openConfirmation(confirm, title, message) {
      this.loading = false;
      return await confirm.open(title, message);
    },

    changeRoute(displayedDate) {
      this.$router.push({
        name: global.ROUTES.monatsansicht.name,
        params: {
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        }
      });
    }
  },

  watch: {
    displayedDate: function () {
      this.initialLoadMonth();
    }
  },
}
</script>
<style type="scss">
#commentbody {
  white-space: pre-line;
}
.v-btn-toggle {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-btn-toggle > .v-btn {
  opacity: 1 !important;
}
</style>